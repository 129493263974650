<template>
  <div class="contentsFIN top-padding">
    <top-home title="绑定用户"></top-home>
    <div class="bindUserInp">
      <van-cell-group>
        <van-field
          label="租户:"
          :value="tenant_name"
          readonly
        />
        <van-field
          v-model="userName"
          label="用户:"
          placeholder="请输入用户姓名/手机号/户号"
        />
        <van-field
          v-model="password"
          type="password"
          label="密码:"
          placeholder="请输入用户密码"
        />
      </van-cell-group>
    </div>

    <van-button
      round
      type="info"
      @click="imsearch"
    >立即查询</van-button>
    <div class="FindUserShowHidden">
      <div style="margin: 10px">
        <div class="hidden-tip">请选择用户绑定：</div>
        <div
          class="user-empty"
          v-if="userData.length<=0"
        >{{tipText}}</div>
        <div
          class="Info"
          v-for="item in userData"
          :key="item.id"
          @click="PopDialog(item)"
        >
          <div class="ellipsis" v-if="item.jfdw_name" >
            <span style="color:#999">甲方单位名称：</span>
            <span>{{ item.jfdw_name }}</span>
          </div>
          <div class="ellipsis">
            <span style="color:#999">姓名：</span>
            <span>{{ item.name }}</span>
          </div>
          <div style>
            <img
              src="@/assets/home/nextarrow.png"
              alt
              width="15px"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="tishi">
      <p class="p1">温馨提示：</p>
      <p class="p2">
        1.绑定用户时请查输入用户名。
        <!-- <a
          href="/ServicePhoneIndex"
          style="color: rgb(129, 172, 252)"
        >查看服务电话</a> -->
        <!-- <br />2.输入户号，查询用户根据显示的用户名称与地址确定是否你需要绑定的用户。 -->
        <br />2.确定需绑定时请选择用户进行绑定，如因绑定错误发生交易并造成相关损失由个人自行负责。
        <br />
      </p>
    </div>
    <van-dialog
      v-model="show"
      title="提示"
      show-cancel-button
      show-confirm-button
      @confirm="determine"
      @cancel="cancelc"
    >你确定要绑定【{{text}}】?</van-dialog>
  </div>
</template>

<script>
import {
  getWxBindWxUser,
  getWxUserList,
} from '@/reuqest/api.js'
import { mapGetters, mapMutations } from 'vuex'
export default {
  data () {
    return {
      show: false, //绑定提示显隐
      tenant_id: '',
      tenant_name: '',
      userData: [], //搜索用户数据
      bindUid: '', //当前选择用户id
      text: '', //当前选择的用户名称
      tipText: '请先查询~',
      password: '',//甲方用户登录密码
      userName: '', //搜索的用户号码
    }
  },
  computed: {
    ...mapGetters(['wxLoginInfo', 'appId', 'bindList']),
  },
  created () {
    this.tenant_name = this.$route.query.tenant_name
    this.tenant_id = this.$route.query.tenant_id
  },

  methods: {
    ...mapMutations(['SET_WX_LOGIN_INFO', 'SET_IS_CUT']),
    imsearch () {
      if (!this.userName) {
        this.$toast('请先输入用户信息~')
        return false
      }
      if (!this.password) {
        this.$toast('请先输入用户密码~')
        return false
      }
      //根据租户id获取用户列表
      getWxUserList({
        parameter: this.userName,
        tenantId: this.tenant_id,
        password: this.password,
      }).then((res) => {
        let { data } = res
        if (data && Object.keys(data).length > 0) {
          this.userData = []
          this.userData.push(data)
        } else {
          this.userData = []
        }
        if (this.userData.length <= 0) {
          this.tipText = '暂未查询到相关用户~'
        }
      })
    },
    PopDialog (item) {
      this.show = true
      this.bindUid = item.id
      this.text = item.name
    },
    determine () {
      //绑定用户
      getWxBindWxUser({
        openId: this.wxLoginInfo.openId,
        wxName: this.wxLoginInfo.nickname,
        userId: this.bindUid,
      }).then(async (res) => {
        if (res.code == 200 && res.data !== 0) {
          this.SET_WX_LOGIN_INFO({
            ...this.wxLoginInfo,
            jfyhWxId: res.data,
          })
          this.SET_IS_CUT(true)
          this.$toast.success('绑定成功,正在前往首页~')
          if (this.bindList.length <= 0) {
            //重新登录
            await this.$store.dispatch('SystemLogin')
          }
          this.$router.push('/home')
        } else {
          this.$toast.fail('绑定失败，请重新尝试~')
        }
      })
    },

    cancelc () { },
  },
}
</script>
 
<style lang="less" scoped>
.contentsFIN {
  min-height: 100vh;
  background-color: rgb(245, 245, 245);
  font-size: 14px;
}
.bindUserInp {
  padding-top: 10px;
  margin-bottom: 10px;
}
.van-button--normal {
  padding: 0 30%;
}
.FindUserShowHidden {
  background-color: #fff;
  border: 1px solid rgb(245, 245, 245);
  border-radius: 5%;

  margin-top: 10px;
  span {
    text-align: left;
  }
  .FindUserShowHidden-word {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  img {
    width: 15px;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }
  .Info {
    text-align: left;
    padding: 10px 0;
    border-bottom: 1px solid rgb(245, 245, 245);
    position: relative;
    font-size: 14px;
  }
  .hidden-tip {
    color: red;
    text-align: left;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(245, 245, 245);
    font-size: 14px;
  }
}
.user-empty {
  padding: 20px 0;
  color: #999;
}
.tishi {
  padding: 10px;
  .p1 {
    text-align: left;
    font-size: 12px;
    color: rgb(255, 76, 76);
  }
  .p2 {
    text-align: left;
    color: rgb(0, 0, 0);
    font-size: 10px;
    line-height: 18px;
  }
}
.title-box {
  background-color: #fff;
  padding: 10px 0;
  color: #232323;
  font-size: 14px;
}
.ellipsis {
  width: 80%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>